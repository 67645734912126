import React from "react";
import "rapidoc";
import "./styles.css";

export default function App() {
  return (
    <rapi-doc
      style={{ height: "100vh", width: "100%" }}
      spec-url="http://localhost:9000/GatewayManager/api/Swagger"
      render-style="read"
      theme="white"
      primary-color="#5c7096"
      api-key-name = "api_key"
      api-key-location = "header"
      api-key-value = "EvolzaApiKey"
      allow-advanced-search="true"
      show-curl-before-try="true"
      allow-search="true"
      nav-bg-color="#3f4d67"
      schema-style="tree"
      schema-description-expanded="true"
      header-color=""
      regular-font="Arial"
      
      show-header="false"

    />
  );
}
